/**
  * 限制只能输入数字，且保留小数后两位
  * @param {string} value - 输入的值
  * @param {string} name - 匹配的对象属性 [mkPrice | slPrice]
  */
function limitInput(value) {
  if (!value) { return '' }
  let result = ("" + value)
  .replace(/[^\d^\.]+/g, "")
  .replace(/^0+(\d)/, "$1")
  .replace(/^\./, "0.") 
  .match(/^\d*(\.?\d{0,2})/g)[0] || "";
  if (result < 0) {
    result = 0
  }
  if (result > 100) {
    result = 100
  }
  return result
}

function limitYuanInput(value) {
  if (!value) { return '' }
  let result = ("" + value)
  .replace(/[^\d^\.]+/g, "")
  .replace(/^0+(\d)/, "$1")
  .replace(/^\./, "0.") 
  .match(/^\d*(\.?\d{0,2})/g)[0] || "";
  if (result > 7) {
    result = result.slice(0, 7)
  }
  return result
}

export default { limitInput, limitYuanInput }