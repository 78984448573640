/**
 * 字符串截取显示，超出长度的显示为省略号(...)
 * @param {*} value 要过滤截取的字符串
 * @param {*} length 要显示的长度
 */
exports.ellipsis = (value, length) => {
  if (!value) return ''
  if (value.length > length) {
    return value.slice(0, length) + '...'
  }
  return value
};

/**
 * 截取日期当中的时间 (2012-15-12 09:00:00 => 09:00:00)
 * @param {string} dateTime 要截取的日期 : 2012-15-120 09:00:00
 * @return {string} 截取后的时间: 09:00:00
 */
exports.dateToTime = (dateTime) => {
  var date = new Date(dateTime);
  return date.toLocaleTimeString();
}

exports.encryptionShow = (data, rule = '$1****$2') => {
  data = data?data:''
  let value = ''
  switch (rule) {
    case '$1****$2':
      value = data.replace(/^(\d{3})\d+(\d{4})$/, '$1****$2')
      break;
    case '$1****$3':
      value = data.replace(/^(.{4}).+(.{4})$/, '$1****$2')
      break;
    case '$1****$4':
      let result = (data+'').split('')
      result[Math.ceil(result.length/2)] = '*'
      result[Math.ceil(result.length/2) + 1] = '*'
      result[Math.ceil(result.length/2) - 1] = '*'
      result[Math.ceil(result.length/2) - 2] = '*'
      value = result.join('')
      break;
  }
  return value
}


exports.formatMoney = (value) => {
  if (!value) return '0.00';

  var intPart = Number(value) - Number(value) % 1; //获取整数部分（这里是windy93的方法）
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断

  var floatPart = ".00"; //预定义小数部分
  var value2Array = value.toString().split(".");

  //=2表示数据有小数位
  if (value2Array.length == 2) {
    floatPart = value2Array[1].toString(); //拿到小数部分

    if (floatPart.length == 1) { //补0,实际上用不着
      return intPartFormat + "." + floatPart + '0';
    } else {
      return intPartFormat + "." + floatPart;
    }

  } else {
    return intPartFormat + floatPart;
  }
}

exports.returnFloat = (value) => {
  var value=Math.round(parseFloat(value)*100)/100;
  var xsd=value.toString().split(".");
  if(xsd.length==1){
    value=value.toString()+".00";
    return value;
  }
  if(xsd.length>1){
    if(xsd[1].length<2){
    value=value.toString()+"0";
    }
  return value;
  }
}

exports.formatEmail = (value) => {
  if (value) {
    let item = value.split('@');
    let itemLeft = item[0].split('');
    itemLeft[itemLeft.length - 1] = '*'
    itemLeft[itemLeft.length - 2] = '*'
    let itemLeftValue = itemLeft.join("");
    let itemRight = item[1].split('');
    itemRight[0] = '*';
    itemRight[1] = '*';
    let itemValueRight = itemRight.join('');
    return itemLeftValue + '@' + itemValueRight;
  }
}
