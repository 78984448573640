export default [{
  path: "/",
  name: "basic",
  component: () => import("@/views/basic/index"),
  children: [
    {//无页面权限
      path: "/noAccess",
      name: "/noAccess",
      component: () => import("@/views/noAccess/noAccess"),
      meta: {
        title: '无页面权限',
        tab: '无页面权限',
        value:'BASIC',
      }
    },

    //------档案管理-------
    {
      path: "/",
      name: "serviceList",
      component: () => import("@/views/basic/fileManage/serviceInfo/list"),
      meta: {
        title: '服务商信息',
        tab: '服务商信息',
        value:"BASIC:FILEMANA:SERVICEINFO",
      }
    },
    {
      path: "/fileManage/businessList",
      name: "businessList",
      component: () => import("@/views/basic/fileManage/business/list"),
      meta: {
        title: '商户列表',
        tab: '商户列表',
        value:"BASIC:FILEMANA:BUSINESSLIST",
      }
    },
    {
      path: "/fileManage/businessDetail",
      name: "businessDetail",
      component: () => import("@/views/basic/fileManage/business/detail"),
      meta: {
        title: '商户详情',
        tab: '商户详情',
        value:"BASIC:FILEMANA:BUSINESSLIST",
      }
    },
    {
      path: "/fileManage/addBusiness",
      name: "addBusiness",
      component: () => import("@/views/basic/fileManage/business/detail"),
      meta: {
        title: '新增商户',
        tab: '新增商户',
        value:"BASIC:FILEMANA:BUSINESSLIST",
      }
    },
    {
      path: "/fileManage/addMerchant",
      name: "addMerchant",
      component: () => import("@/views/basic/fileManage/business/add_merchant"),
      meta: {
        title: '新增商户',
        tab: '新增商户',
        value:"BASIC:FILEMANA:BUSINESSLIST",
      }
    },
    {
      path: "/fileManage/addMerchantDetail",
      name: "addMerchantDetail",
      component: () => import("@/views/basic/fileManage/business/add_merchant"),
      meta: {
        title: '商户详情',
        tab: '商户详情',
        value:"BASIC:FILEMANA:BUSINESSLIST",
      }
    },
    {
      path: "/fileManage/accountList",
      name: "accountList",
      component: () => import("@/views/basic/fileManage/account/list"),
      meta: {
        title: '分账方列表',
        tab: '分账方列表',
        value:"BASIC:FILEMANA:ACCOUNTLIST",
      }
    },
    {
      path: "/fileManage/accountDetail",
      name: "accountDetail",
      component: () => import("@/views/basic/fileManage/account/detail"),
      meta: {
        title: '分账方详情',
        tab: '分账方详情',
        value:"BASIC:FILEMANA:ACCOUNTLIST",
      }
    },
    {
      path: "/fileManage/addAccount",
      name: "addAccount",
      component: () => import("@/views/basic/fileManage/account/detail"),
      meta: {
        title: '新增分账方',
        tab: '新增分账方',
        value:"BASIC:FILEMANA:ACCOUNTLIST",
      }
    },

    //------余额账户管理-------
    { //余额账户管理-余额账户列表
      path: "/balanceList/balance_list",
      name: "balance_list",
      component: () => import("@/views/basic/balance/balanceList/balance_list"),
      meta: {
        title: '余额账户列表',
        tab: '余额账户列表',
        value:'BASIC:BALANCE:LIST',
      }        
    },
    { //余额账户管理-余额账户列表-转账
      path: "/balanceList/ban_transfer",
      name: "/balanceList/ban_transfer",
      component: () => import("@/views/basic/balance/balanceList/ban_transfer"),
      meta: {
        title: '余额账户列表-转账',
        tab: '余额账户列表-转账',
        value:'BASIC:BALANCE:LIST',
      }        
    },
    { //余额账户管理-余额账户列表-结算
      path: "/balanceList/ban_settlement",
      name: "/balanceList/ban_settlement",
      component: () => import("@/views/basic/balance/balanceList/ban_settlement"),
      meta: {
        title: '余额账户列表-结算',
        tab: '余额账户列表-结算',
        value:'BASIC:BALANCE:LIST',
      }        
    },
    { //余额账户管理-入账查询
      path: "/entryInquiry/entryInquiry_sear",
      name: "entryInquiry_sear",
      component: () => import("@/views/basic/balance/entryInquiry/entryInquiry_sear"),
      meta: {
        title: '入账查询',
        tab: '入账查询',
        value:'BASIC:BALANCE:ENTRYSEAR',
      }        
    },
    { //余额账户管理-入账查询
      path: "/entryInquiry/entryInquiry_detail",
      name: "entryInquiry_detail",
      component: () => import("@/views/basic/balance/entryInquiry/entryInquiry_detail"),
      meta: {
        title: '入账查询-详情',
        tab: '入账查询-详情',
        value:'BASIC:BALANCE:ENTRYSEAR/DETAIL',
      }        
    },
    { //余额账户管理-转账查询
      path: "/transferSear/transfer_sear",
      name: "transfer_sear",
      component: () => import("@/views/basic/balance/transferSear/transfer_sear"),
      meta: {
        title: '转账查询',
        tab: '转账查询',
        value:'BASIC:BALANCE:TRANSFER',
      }        
    },
    { //余额账户管理-转账查询-详情
      path: "/transferSear/transfer_detail",
      name: "transfer_detail",
      component: () => import("@/views/basic/balance/transferSear/transfer_detail"),
      meta: {
        title: '转账查询-详情',
        tab: '转账查询-详情',
        value:'BASIC:BALANCE:TRANSFER/DETAIL',
      }        
    },
    { //余额账户管理-结算查询
      path: "/settleSear/settle_sear",
      name: "settle_sear",
      component: () => import("@/views/basic/balance/settleSear/settle_sear"),
      meta: {
        title: '结算查询',
        tab: '结算查询',
        value:'BASIC:BALANCE:SETTLE',
      }        
    },
    { //余额账户管理-结算查询-详情
      path: "/settleSear/settle_detail",
      name: "settle_detail",
      component: () => import("@/views/basic/balance/settleSear/settle_detail"),
      meta: {
        title: '结算查询-详情',
        tab: '结算查询-详情',
        value:'BASIC:BALANCE:SETTLE/DETAIL',
      }        
    },



    //------系统管理-------
    {//角色管理
      path: "/system/role_jsgl",
      name: "role_jsgl",
      component: () => import("@/views/basic/system/role_jsgl/index"),
      meta: {
        title: '角色管理',
        tab: '角色管理',
        value:"BASIC:SYSTEM:ROLES",
      }
    },
    {//员工管理
      path: "/system/staff_yggl",
      name: "staff_yggl",
      component: () => import("@/views/basic/system/staff_yggl/index"),
      meta: {
        title: '员工管理',
        tab: '员工管理',
        value:"BASIC:SYSTEM:EMPLOYEES",
      }
    },
    {//安全设置
      path: "/system/security_aqsz",
      name: "security_setting",
      component: () => import("@/views/basic/system/security_aqsz/index"),
      meta: {
        title: '安全设置',
        tab: '安全设置',
        value:"BASIC:SYSTEM:SAFE",
      }
    },


    { //默认
      path: "/404",
      name: "default",
      component: () => import("@/views/default"),
    }
  ]
},

]
