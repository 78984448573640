<template>
  <Modal v-model="visible" :title="title" closable width="490px" @on-cancel="handleClickClose">
    <!-- 店铺是否授权正常 -->
    <div class="lissContent" v-if="mode ==='unusual'">
      <p>店铺授权异常，请更新授权 <Button class="addBt guide" @click="lissSeeGuide()">查看指引</Button></p>
    </div>
    <!-- code登录 无权限提示去开通店铺，有权限正常使用-->
    <div class="lissContent" v-if="mode ==='mchIdNall'">
      <p><i class="el-icon-warning"></i>店铺钱包未开通无法使用此功能，请前往开通。</p>
    </div>
    <!-- 安全等级：低 （没有验证） -->
    <!-- 安全等级：中 -->
    <div class="lissContent" v-if="mode === '2'">
      <div class="item">
        <div class="title"><i>*</i>安全密码</div>
          <Input v-model="params.inputSecurityPassword" type="password" password maxlength="128" placeholder="请输入安全密码(6位数字)" size="large" class="width100"></Input>
        </div>
        <div class="item">
          <div class="title"><i>*</i>手机号码</div>
          <Input v-model="phone" maxlength="11" placeholder="输入手机号码" size="large" class="width100" disabled></Input>
        </div>
        <div class="item itemCode">
        <div>
          <div class="title"><i>*</i>验证码</div>
          <Input  maxlength="6" v-model="params.validateCode" placeholder="输入短信验证码" size="large"></Input>
        </div>
        <span class="getCode" @click="getOldCode">{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
        </div>
      </div>
      <!-- 安全等级：高 -->
      <div class="lissContent" v-if="mode ==='3'">
      <div class="item">
        <div class="title"><i>*</i>安全密码</div>
        <Input v-model="params.inputSecurityPassword" type="password" password maxlength="128" placeholder="请输入安全密码(6位数字)" size="large" class="width100"></Input>
      </div>
      <div class="item">
        <div class="title"><i>*</i>手机号码</div>
        <Input v-model="phone" maxlength="11" placeholder="输入手机号码" class="width100" size="large" disabled></Input>
      </div>
      <div class="item itemCode">
        <div>
          <div class="title"><i>*</i>验证码</div>
          <Input  maxlength="6" v-model="params.validateCode" placeholder="输入短信验证码" size="large"></Input>
        </div>
        <span class="getCode" @click="getOldCode">{{timeOut==0?'获取验证码':`倒计时${timeOut}s`}}</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <Button class="exitBt" @click="handleClickClose" v-if="mode !=='unusual'">取 消</Button>
      <Button class="addBt" @click="handleClickConfirm" v-if="mode !=='unusual' && mode !=='mchIdNall'">确 定</Button>
      <Button class="addBt" @click="handleClickClose" v-if="mode ==='unusual'">完 成</Button>
      <Button class="addBt" @click="lissOpenWallet" v-if="mode ==='mchIdNall'">去开通</Button>
    </span>
  </Modal>
</template>
<script>
import { getSetTraDataHome } from "@/api/basic/index.js";
import { validateCode1, verifyCode, getSecurity } from "@/api/common.js";
import md5 from 'js-md5';
export default {
  name:"lissTipSModel",
  props:{
    // 是否显示对话框
    value: {
      type: Boolean,
      required: true
    },
    auth: {
      type: String,
      default: ''
    },
    title: String,
    content: String,
    mode: {
			type: String,
			default: '2'
		},
    params: {
      type:Object,
      default: () => {
        return{
          inputSecurityPassword:"",//安全密码
          phoneNumber:'',//手机号
          validateCode:"",//验证码
          passwrod:"",//密码
        }
      }
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    okText: {
      type: String,
      default: "确定",
    }
  },
  watch: {
    value (newVal) {
      this.visible = newVal
      if (!newVal) {
        this.params.validateCode = ''
        this.params.inputSecurityPassword = ''
        clearInterval(this.codeShow)
        this.timeOut = 0
      }
    }
  },

  created () {
    this.visible = this.value
    if (!this.phone) {
      this.getUserInfo()
    }
  },

  data() {
    return {
      visible:false,
      timeOut: 0,
      codeShow: "",
      phone: ''
    };
  },
  methods: {
    getUserInfo() {
      const { phoneNumber } = JSON.parse(sessionStorage.getItem('userInfo'))
      this.phone =  phoneNumber && phoneNumber.replace(/^(\d{3})\d+(\d{4})$/, '$1****$2')
    },
    lissBtn(){
      this.$emit("lissBtn");
      let params = this.params;
      this.$emit("confirm", params);
    },
    lissSeeGuide(){
      this.$router.push({ name: '/openWallet/stepOneRenewal' })
      this.$emit("close");
    },
    handleClickClose () {
      this.visible = false
      this.$emit('input', false)
      this.$emit("close");
    },
    handleClickConfirm () {
      if (!this.params.inputSecurityPassword) {
        this.$Message.error("请输入安全密码");
        return;
      }
      if (!this.params.validateCode) {
        this.$Message.error("请输入验证码");
        return;
      }
      const data = {
        value: this.auth,
        code: this.params.validateCode,
        inputSecurityPassword: this.params.inputSecurityPassword
      }
      data.inputSecurityPassword = md5(data.inputSecurityPassword)
      this.visible = false
      this.$emit('input', false)
      verifyCode(data).then(res => {
				if (res && res.resultStatus) {
					this.$emit("confirm", data)
				} else {
          this.$emit("fail")
        }
			})
    },
    lissOpenWallet(){
      getSetTraDataHome().then((res) => {
        if (res) {
          const walletMchStatus = res.resultData.walletMchProfilePO
          if(walletMchStatus==null){
            this.$router.push({
              name: '/openWallet/stepOne'
            })
          }else{
            if(walletMchStatus.status == 3 || walletMchStatus.status == 1){
              this.$router.push({
                name: '/openWallet/stepThree',
                query: walletMchStatus
              })
            }else{
              this.$router.push({
                name: '/openWallet/stepTwoStatus',
                query: walletMchStatus
              })
            }
          }
        } else {
          this.$router.push({
            name: '/openWallet/stepOne'
          })
        }
        this.$emit("close");
      })
    },
    getOldCode(){
      if (this.timeOut != 0) {
        return;
      }
      this.timeOut = 60
      validateCode1({ value: this.auth }).then(res => {
        if (res) {
        	this.codeShow = setInterval(() => {
        		if (this.timeOut == 0) {
        			clearInterval(this.codeShow)
        		} else {
        			this.timeOut--
        		}
        	}, 1000)
        }else{
          clearInterval(this.codeShow)
          this.timeOut = 0
        }
      })
    },
  },

};
</script>
<style scoped>
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}
.addBt:hover{
  border-color: var(--themeColor);
  background-color: var(--themeColor);
  color: #ffffff;
}
.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}
.exitBt:hover{
  background-color: #ffffff;
  color: var(--title);
}
.lissContent {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  padding-top: 20px;
}
.lissContent p{
  display: flex;
  align-items: center;
}
.lissContent p .el-icon-warning{
  color: #e6a23c;
  font-size: 24px;
  margin-right: 5px;
}
.lissContent .title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
  padding-left: 0px;
  text-align: left;
}

.lissContent .title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.lissContent .ivu-input,
.lissContent .el-select {
  margin-bottom: 7px;
}

.lissContent .item {
  /* float: left; */
  width: 320px;
}
.lissContent .guide{
  height:32px;margin-left:20px;width: 90px;
}
.lissContent .item.itemCode{
  /* width: 100% !important; */
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.lissContent .item.itemCode>div{
  width: calc(100% - 100px);
}
.lissContent .item .getCode{
    width: 80px;
    text-align: right;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-bottom: 7px;
    color: var(--themeColor);
    margin-left: 0px;
}
</style>
