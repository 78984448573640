import axios from '@/utils/request'

// 登录
export function isLogin (data) {
  return axios({
    url: `/manager/user/login`,
    method: 'post',
    data
  })
}

// 修改密码
export function setPass (data) {
  return axios({
    url: `/manager/user/modifypwd`,
    method: 'put',
    data
  })
}
// 退出
export function layout () {
  return axios({
    url: `/manager/user/logout`,
    method: 'post'
  })
}
// 用户-忘记密码
export function resetpwd (data) {
  return axios({
    url: `/manager/user/resetpwd`,
    method: 'put',
		data
  })
}
// 短信验证码(根据商户号)
export function validatecode (params) {
  return axios({
    url: `/manager/user/validatecode`,
    method: 'get',
		params
  })
}