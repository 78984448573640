<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  watch: {
    $route: {
      handler: function (val) {
        this.getRole(val.fullPath);
        if (val.name == "default") {
          sessionStorage.setItem("active_tab", "");
          return;
        }
        if (val.meta.tab) {
          let data = {
            title: val.meta.tab,
            fullPath: val.fullPath,
          };
          let tabs = sessionStorage.getItem("tabs")
            ? JSON.parse(sessionStorage.getItem("tabs"))
            : [];
          if (JSON.stringify(tabs).indexOf(JSON.stringify(data)) === -1) {
            tabs.push(data);
            sessionStorage.setItem("tabs", JSON.stringify(tabs));
          }
          sessionStorage.setItem("active_tab", val.fullPath);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    getRole(fullPath) {
      const _resources = sessionStorage.getItem("userInfo")
        ? (JSON.parse(sessionStorage.getItem("userInfo")).resources += "")
        : "";
      if (_resources == "ALL") {
        return;
      }
      switch (fullPath) {
        case "/":
          if (_resources.split(";").indexOf("BASIC") === -1) {
            this.$router.push("/basic404");
          }
          break;
        // case "/":
        //   if (_resources.split(";").indexOf("SHAREASS") === -1) {
        //     this.$router.push("/shareass404");
        //   }
        //   break;
        // case "/data":
        //   if (_resources.split(";").indexOf("ORDEREASS") === -1) {
        //     this.$router.push("/ordereass404");
        //   }
        //   break;
      }
    },
  },
};
</script>
<style>
@import "../src/assets/css/color.css";
#app {
  width: 100%;
  background: #fff;
  font-family: "Microsoft YaHei", "PingFang SC", "Helvetica Neue", Helvetica,
    "Hiragino Sans GB", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  overflow-y: scroll;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
dl,
td,
dd,
hr,
input,
body,
figure {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

body {
  width: 100%;
  font-size: 12px;
  font-family: "微软雅黑";
  padding-right: 0 !important;
}
/* body {
  width: 100vw;
  overflow: hidden;
} */
img {
  display: block;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: normal;
}

em,
i {
  font-style: normal;
}

input {
  outline: none;
}

*:focus {
  outline: none;
}

td {
  padding: 0 5px !important;
}

/*去掉INPUT在部分浏览器里点击里的外边线*/
.clear:after {
  content: " ";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  /*或overflow:hidden;*/
}

.float_left {
  float: left;
}

.width100 {
  width: 100%;
}

.hidden1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cover_view {
  z-index: 9999 !important;
}

.overAccount_ul_title i,
.uploader1 h4 i {
  font-family: "Microsoft YaHei";
  color: var(--themeColor);
  padding-right: 3px;
}

/* 页签 */
.el-tabs--top .el-tabs__item .el-icon-close {
  opacity: 0;
  margin-left: -14px;
  transition: all 0.3s;
}

.el-tabs--top .el-tabs__item:hover .el-icon-close {
  opacity: 1;
  margin-left: 5px;
}

.el-tabs--top .el-tabs__item:hover,
.el-tabs--top .el-tabs__item.is-active {
  color: var(--themeColor) !important;
}

.el-tabs--top .el-tabs__item.is-active::after {
  content: "";
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--themeColor);
}

.el-tabs--top .el-tabs__active-bar {
  display: none;
}

.el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px !important;
}

.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}

.el-tabs--top .el-tabs__nav-wrap::after {
  display: none;
}

.el-tabs--top .el-tabs__item {
  height: 45px;
  line-height: 50px;
  font-size: 12px !important;
  /* color: #636d7e !important; */
  color: var(--label) !important;
}

.el-tabs--top .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: var(--themeColor) !important;
}

.el-upload--picture-card:hover,
.el-upload:focus {
  color: #606266 !important;
}

.avatar-uploader .el-upload:hover,
.avatar-uploader .el-upload {
  border: 0 !important;
}

.el-picker-panel__shortcut:hover {
  color: var(--themeColor) !important;
}

.el-picker-panel__icon-btn:hover {
  color: var(--themeColor) !important;
}

.el-date-picker__header-label.active,
.el-date-picker__header-label:hover {
  color: var(--themeColor) !important;
}

.el-tabs--top .el-tabs__item.is-active::after {
  height: 2px !important;
}

.el-input__inner {
  background-color: #f8f8fa !important;
  border: none !important;
}

/* 浏览器滚动条整体样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*浏览器滚动条里面小方块*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgb(149, 149, 149);
}
/*浏览器滚动条里面轨道*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

/* 上传图片 删除 统一样式 */
.avatar-uploader {
  position: relative;
}
.avatar-uploader .avatar-uploader-span {
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  padding: 2px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: var(--themeColor);
  text-align: right;
  z-index: 99;
  cursor: pointer;
}

/* 加载中 */
.loadingBox .el-loading-spinner i {
  color: var(--themeColor) !important;
  font-size: 36px !important;
}
.loadingBox .el-loading-spinner .el-loading-text {
  color: var(--themeColor) !important;
  font-size: 16px !important;
}

/* 暂无数据提示样式 */
.focus-message .el-message__content,
.focus-message .el-icon-info {
  color: var(--themeColor) !important;
}

/* 列表表格样式 */
.tab1 {
  width: 100%;
  min-height: 650px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* overflow-x: auto; */
}
.tab1 .tab tr {
  padding-left: 20px;
  height: 60px;
  /* border-bottom: 1px solid #EBEEF5; */
  border-bottom: 1px solid var(--tabBor);
}
.tab1 td {
  padding: 0 6px;
  word-break: normal;
  white-space: nowrap;
  box-sizing: border-box;
}
.tab {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  /* border-bottom: 1px solid #EBEEF5; */
  border-bottom: 1px solid var(--tabBor);
}
.tab tr td p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.tab_title {
  /* background: rgba(244, 245, 246, 1); */
  background: var(--tabBg);
}

.lianjie {
  /* .tab1 .lianjie */
  padding: 0 5px;
  color: var(--togoBtn);
  cursor: pointer;
  background: transparent;
  border: none;
  position: relative;
  font-size: 14px;
}
.lianjie > span {
  /* .tab1 .lianjie>span */
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  background: rgba(0, 0, 0, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
}

/* 页面切换样式 */
.header {
  /* line-height: 40px; */
  width: 100%;
  height: 32px;
  border-bottom: 2px solid #ebeef5;
  position: relative;
  top: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.header span {
  display: inline-block;
  height: 100%;
  width: 100px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  cursor: pointer;
}
.header span.active {
  color: var(--themeColor);
  border-bottom: 2px solid var(--themeColor);
  box-sizing: border-box;
  height: 32px;
}

.header #fzcolor {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--themeColor);
  border-bottom: 2px solid var(--themeColor);
}

.header_switch {
  line-height: 40px;
  width: 100%;
  height: 40px;
  margin-top: 20px;
}
.header_switch div {
  cursor: pointer;
}
.header_switch .right {
  display: inline-block;
  float: right;
  width: 74px;
  height: 40px;
  color: var(--title);
  font-size: 12px;
  text-align: center;
  line-height: 40px;
}

.header_switch .right.active {
  color: var(--themeColor);
  box-sizing: border-box;
  border-bottom: 2px solid var(--themeColor);
}

.con_from .lineP {
  display: flex;
  flex-flow: wrap;
  margin: 20px 0px 0px;
  width: 100%;
}
.con_from .lineP:first-child {
  margin: 0px;
}
.con_from .lineP .item {
  display: flex;
  align-content: center;
  height: 40px;
  line-height: 40px;
  width: 27%;
  margin-left: 9.5%;
  font-size: 14px;
}
.con_from .lineP .item:first-child {
  margin-left: 0%;
}
.con_from .lineP .itembtn {
  display: flex;
  margin-left: auto;
  width: 30%;
  align-items: center;
  justify-content: flex-end;
}
.con_from .lineP .itembtn button {
  margin-left: 20px;
}

.con_from .lineP .item > i {
  width: 70px;
  text-align: right;
  margin-right: 10px;
}
.con_from .lineP .item .ivu-input-wrapper,
.con_from .lineP .item .ivu-select {
  width: calc(100% - 60px - 10px);
}
.con_from .lineP .item .ivu-date-picker {
  width: calc(100% - 60px - 10px) !important;
}
.con_from .lineP .item .ivu-date-picker .ivu-input-wrapper {
  width: 100% !important;
}
.con_from .lineP .item .el-select .el-input {
  width: 100%;
}
.con_from .lineP .itemTime {
  width: 47.217%;
}
.con_from .lineP .itemTime .el-input {
  width: calc((100% - 60px - 24.67px -10px) / 2);
}
.con_from .lineP .itemTwo {
  width: 38%;
  margin-left: 4%;
}
.con_from .lineP .itemTwo:first-child {
  margin-left: 0%;
}
.con_from .lineP .itemTwobtn {
  width: 20%;
}

/* 页面数量板块 */
.jiaoyi {
  width: 100%;
  height: 90px;
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.jiaoyi_box {
  float: left;
  /* width: 23.65%; */
  width: calc((100% - 60px) / 4);
  height: 90px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 9px 0px rgb(148 148 148 / 21%);
  /* box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05); */
  border-radius: 5px;
}
.jiaoyi_box p {
  width: 100%;
  text-align: center;
  padding-top: 13px;
}
.jiaoyi_box p b {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: var(--themeColor);
}
.jiaoyi_box p i {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

/* 按钮样式 */
.searchBt,
.searchBt:focus,
.searchBt:hover {
  /* 查询 */
  float: right;
  background: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
  border-radius: 4px;
  font-size: 16px;
  width: 90px;
  height: 36px;
  padding: 0 !important;
  color: #ffffff !important;
  cursor: pointer;
}
.outputBt {
  /* 导出 */
  float: right;
  width: 90px;
  height: 36px;
  border: 1px solid #d7dae2 !important;
  background-color: #fff !important;
  border-radius: 4px;
  margin-left: 20px !important;
  padding: 0 !important;
  font-size: 16px;
  color: #606266 !important;
  cursor: pointer;
}
.outputBt:hover {
  background-color: #fff !important;
  color: var(--themeColor) !important;
}

@media screen and (min-width: 1921px) {
  .el-menu-item,
  .el-tabs--top .el-tabs__item {
    font-size: 14px !important;
  }
}
</style>
<style lang="less">
@import "../src/assets/css/iview_ui.less";
</style>
