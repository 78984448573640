import axios from '@/utils/request'

// 商户列表（下拉筛选）
export function merchantsDrop(params) {
  return axios({
    url: `/manager/common/merchants/dropdown`,
    method: 'get',
		params
  })
}
//代理商列表（下拉筛选）
export function agentsDrop(params) {
  return axios({
    url: `/manager/common/agents/dropdown`,
    method: 'get',
		params
  })
}
//支付产品列表（下拉筛选）
export function payproductsDrop(params) {
  return axios({
    url: `/manager/common/payproducts/dropdown`,
    method: 'get',
		params
  })
}
//支付机构列表（下拉筛选）
export function organizationsDrop(params) {
  return axios({
    url: `/manager/common/organizations/dropdown`,
    method: 'get',
		params
  })
}
//支付通道列表（下拉筛选）
export function paychannelsDrop(params) {
  return axios({
    url: `/manager/common/paychannels/dropdown`,
    method: 'get',
		params
  })
}
//小程序列表（下拉筛选）
export function appsDrop(params) {
  return axios({
    url: `/manager/common/apps/dropdown`,
    method: 'get',
		params
  })
}
//三方列表（下拉筛选）
export function thirdsDrop(params) {
  return axios({
    url: `/manager/common/thirds/dropdown`,
    method: 'get',
		params
  })
}

// 短信验证码(手机号获取)
export function validatecode(params) {
  return axios({
    url: `/manager/common/validatecode`,
    method: 'get',
		params
  })
}
// 安全设置-账号信息
export function getSecurity() {
	return axios({
		url: `/manager/system/security`,
		method: 'get'
	})
}

// 安全设置-获取短信验证码
export function getValidatecode() {
	return axios({
		url: `/manager/system/security/phone/validatecode`,
		method: 'get'
	})
}

// 获取路由结构
export function getDirectoryByTree() { // 管理员页面
	return axios({
		url: `/manager/system/getDirectoryByTree`,
		method: 'get'
	})
}

export function getSminByTree() { //  员工页面
	return axios({
		url: `/manager/system/getDirectoryByTree/staff`,
		method: 'get'
	})
}

export function getByTreeStaff() { // 管理员/员工-新增/编辑
	return axios({
		url: `/manager/system/getDirectoryByTree/staff`,
		method: 'get'
	})
}

// 风险验证-获取验证码
export function validateCode1(params) {
	return axios({
		url: `/manager/system/security/phone/validatecode`,
		method: 'get',
		params
	})
}

// 风险验证-验证
export function verifyCode(data) {
	return axios({
		url: `/manager/system/security/code/verify`,
		method: 'post',
		data
	})
}
