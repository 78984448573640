import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",//存储token
    sidebarSettle:"",//版心宽度
    isCollapse: false,
    rolesData:"",//路由

    file_business: null, // 档案管理-商户列表
    file_account: null, // 档案管理-分账方列表
   
    trading_zhlb: null,//余额账户管理-余额账户列表
    trading_entrySear:null,//余额账户管理-入账查询
    trading_refund_sg: null,//余额账户管理-结算查询
    trading_refund_zz: null,//余额账户管理-转账查询

    system_roleQuery:null,//系统管理-角色管理
    system_staffQuery: null,//系统管理-员工管理
    system_applyQuery: null,

    business_data: null, // 商户编辑
    account_data: null, // 分账方编辑

  },
  getters:{
    getToken(state){
      return state.token ;
    }
  },
  mutations: {
    setCollapse(state, isCollapse) {
      state.isCollapse = isCollapse
    },
    // 修改token，并将token存入localStorage
    setToken(state,token) {
      state.token = token;
      localStorage.setItem("token",token);
    },

    setRolesData(state,rolesData) {
      state.rolesData = rolesData;
      localStorage.setItem("rolesData",rolesData);
    },

    set_business_data(state, value) {
      state.business_data = value
    },

    set_account_data(state, value) {
      state.account_data = value
    },

    sidebar_Settle(state, value) {
      state.sidebarSettle = value
    },

    // 档案管理-商户列表
    file_setBusiness(state, value) {
      state.file_business = value
    },

    // 档案管理-分账方列表
    file_setAccount(state, value) {
      state.file_account = value
    },

     //余额账户管理-余额账户列表
    trading_setZhlb(state, value) {
      state.trading_zhlb = value
    },
    //余额账户管理-入账查询
    trading_setentrySear(state, value) {
      state.trading_entrySear = value
    },
    //余额账户管理-结算查询数据存储
    trading_setRefund_sg(state, value) {
      state.trading_refund_sg = value
    },
    //余额账户管理-转账查询数据存储
    trading_setTransfer_zz(state, value) {
      state.trading_refund_zz = value
    },

    //系统管理-角色管理
    system_setRoleQuery(state, value) {
      state.system_roleQuery = value
    },
    // 系统管理-员工管理查询存储
    system_setStaffQuery(state, value) {
      state.system_staffQuery = value
    },
    system_setApplyQuery(state, value) {
      state.system_applyQuery = value
    },

  },
  actions: {
  },
  modules: {
  },
  
  plugins: [
    new createPersistedstate({
      token: localStorage.getItem("token"),
      rolesData:localStorage.getItem("rolesData"),
    }),
  ],
})
