/**
 * 插件注册
 * created by 2020-04-27
 */
import filters from '../filters'
import VeLine from 'v-charts/lib/line.common'
import VePie from 'v-charts/lib/pie.common'
import limit from '../utils/limit'
import CustomModal from '../components/CustomModal'
import Vue from 'vue'

let ConfirmBox = Vue.extend(CustomModal);

const confirm = (content) => {
  return new Promise((res, rej) => {
    let confirmDom = new ConfirmBox({
      el: document.createElement('div')
    })
    document.body.appendChild(confirmDom.$el)
    confirmDom.content = {
      ...content,
    };
    confirmDom.confirm = () => {
      res();
      confirmDom.content.isShow = false;
    }
    confirmDom.cancel = () => {
      rej();
      confirmDom.content.isShow = false;
    }
  })
}

export default {
  install (Vue) {
    // 挂载原型
    Vue.prototype.$limit = limit;
    
    Vue.prototype.$confirm = confirm;

    // 注册全局组件
    Vue.component(VeLine.name, VeLine)
    Vue.component(VePie.name, VePie)
    Vue.component('LevelButton', () => import('@/components/LevelButton'));
    Vue.component('LissModel', () => import('@/components/lissModel'));

    // 注册全局过滤器
    Vue.prototype.$filter = filters
    Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))
  }
}
