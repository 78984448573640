import axios from '@/utils/request'

// 电子账簿管理-电子账簿列表 -- 测试


export function setShop(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}`,
    method: 'put',
		data
  })
}
// ----------------------------------

// 分享员管理-我的分享员
export function myShareList(params) {
  params.loading = true
  return axios({
    url:`/manager/operating/SharerInfo`,
    method:'get',
    params
  })
}

// 分享员管理-我的分享员 - 同步
export function myShareSync(appId) {
  return axios({
    url:`/wxapi/sharer/${appId}/sync`,
    method:'post',
  })
}

// 分享员管理-我的分享员-关联微信
export function associatedWx(sharerId,params) {
  return axios({
    url:`/manager/operating/associatedWx/${sharerId}`,
    method:'get',
    params
  })
}

// 分享员管理-我的分享员-(主页)详情
export function myShareDetail (id) {
  return axios({
    url: `/manager/operating/SharerInfo/${id}`,
    method: 'get',
  })
}

// 分享员管理-我的分享员-(主页)-编辑
export function mySharerEdit (data) {
  return axios({
    url: `/manager/operating/sharerEdit`,
    method: 'post',
    data
  })
}

// 分享员管理-我的分享员-(主页)-解绑
export function mySharerUnbind (params) {
  return axios({
    url: `/manager/operating/thirds/unbind`,
    method: 'get',
    params
  })
}

// 分享员管理-邀请分享员   
export function invitedShareList(params) {
  params.loading = true
  return axios({
    url:`/manager/operating/wxInvitation`,
    method:'get',
    params
  })
}

// 分享员管理-邀请分享员-终止   
export function invitedShareStop(id) {
  return axios({
    url:`/manager/operating/setWxInvitation/${id}`,
    method:'get',
  })
}

// 分享员管理-邀请分享员-新增邀请
export function addInvitedShare(data) {
  return axios({
    url: `/manager/operating/addWxInvitation`,
    method: 'post',
		data
  })
}

// 分享员管理-邀请分享员-详情
export function invitedShareDetail(id) {
  return axios({
    url: `/manager/operating/wxInvitationDetail/${id}`,
    method: 'get'
  })
}

// 分享员管理-邀请分享员-详情-绑定
export function invitedDetailBind(id,params) {
  return axios({
    url: `/manager/operating/putWxInvitation/${id}`,
    method: 'get',
    params
  })
}


// 佣金管理-佣金列表 （分享员id默认传0）
export function commissionList(sharerId,params) {
  params.loading = true
  return axios({
    url:`/manager/operating/settleOrderInfo/${sharerId}`,
    method:'get',
    params
  })
}

// 佣金管理-佣金列表-导出
export function exportSettleorders(params) {
  return axios({
    responseType: 'blob',
    url: `/manager/operating/settleOrderInfo/export`,
    method: 'get',
    params
  })
}

//  佣金管理-佣金列表-详情
export function commissionListDetail(id) {
  return axios({
    url: `/manager/operating/settleOrderInfo/detail/${id}`,
    method: 'get'
  })
}

// 佣金管理-佣金列表-查询合并支付信息 
export function paySharerQuery(params) {
  return axios({
    url:`/manager/trade/allocfund/queryPaySharer`,
    method:'get',
    params
  })
}

// 佣金管理-佣金列表-支付
export function paysSharer(data) {
  return axios({
    url: `/manager/trade/allocfund/pays/sharer`,
    method: 'post',
    data
  })
}


// 佣金管理-计佣规则
export function commissionRule(data) {
  return axios({
    url: `/manager/system/set/sharer/rule`,
    method: 'put',
		data
  })
}
export function getWxCommRule(appId) {
  return axios({
    url: `/manager/operating/getWxInvitation/${appId}`,
    method: 'get'
  })
}


// 商品佣金
export function goodsCommList(params) {
  params.loading = true
  return axios({
    url:`/manager/operating/listProduct`,
    method:'get',
    params
  })
}

// 商品佣金 - 设置费率
export function goodsCommRate(id,params) {
  return axios({
    url:`/manager/operating/product/${id}`,
    method:'get',
    params
  })
}

// 资金管理-账户资产
export function getSetTraDataHome() {
  return axios({
    url: `/manager/account/assets`,
    method: 'get'
  })
}

// 资金管理-账户资产-切换查询
export function accSwitchSear(mchId) {
  return axios({
    url: `/manager/account/list/${mchId}`,
    method: 'get'
  })
}
// 资金管理-账户资产-切换
export function accountSwitch(id) {
  return axios({
    url: `/manager/account/switchTo/${id}`,
    method: 'get'
  })
}
// 资金管理-账户资产-更换
export function accountChange(data) {
  return axios({
    url: `/manager/account/settlement`,
    method: 'post',
    data
  })
}

// 资金管理-账户资产-更换-获取开户银行列表
export function getbanklist(params) {
  return axios({
    url: `/manager/account/thirds/getbanklist`,
    method: 'get',
    params
  })
}

// 资金管理-账户资产-更换-城市列表
export function getcity(params) {
  return axios({
    url: `/manager/account/thirds/getcity`,
    method: 'get',
    params
  })
}
// 资金管理-账户资产-更换-支行列表
export function getsubbranch(params) {
  return axios({
    url: `/manager/account/thirds/getsubbranch`,
    method: 'get',
    params
  })
}
// 资金管理-账户资产-更换- 小店账户余额
export function getbalance() {
  return axios({
    url: `/manager/account/thirds/getbalance`,
    method: 'get',
  })
}
// 资金管理-账户资产-店铺钱包总资产-提现
export function getfeeCheck(mchId) {
  return axios({
    url: `/manager/account/feeCheck/${mchId}`,
    method: 'get'
  })
}
export function getAltwithdrawSettlement(data) {
  return axios({
    url: `/manager/trade/allocfund/pays/withdraw`,
    method: 'post',
    data
  })
}
export function getMerchantsDetail(thirdAccountId) {
  return axios({
    url: `/manager/trade/allocfund/merchants/${thirdAccountId}`,
    method: 'get'
  })
}
// 资金管理-账户资产-提现至钱包账户  
export function submitwithdraw(params) {
  return axios({
    url: `/manager/account/thirds/submitwithdraw`,
    method: 'get',
    params
  })
}
// 资金管理-账户资产-充值-点击充值-获取流水号
export function postSaveOrderNo(params) {
  return axios({
    url: '/manager/trade/pay/orderNo',
    method: 'get',
    params
  })
}
export function postSaveRecharge(params) {
  return axios({
    url: '/manager/trade/pay',
    method: 'get',
    params
  })
}
export function getRechargeDetails(id) {
  return request({
    url: `/manager/trade/recharge/` + id,
    method: 'get',
  })
}
export function getAltMchTransfer(data) {
  return axios({
    url: `/manager/trade/allocfund/pays/altMchTransfer`,
    method: 'post',
    data
  })
}

// 资金管理-账户资产-去开通
export function authorizatioCheck() { // 授权校验
  return axios({
    url: `/manager/account/authorizatioCheck`,
    method: 'get'
  })
}

export function enterprise() { // 开通校验
  return axios({
    url: `/manager/account/OpenWalletCheck/enterprise`,
    method: 'post'
  })
}

export function OpenWalletCheck(data) { // 开通校验
  return axios({
    url: `/manager/account/OpenWalletCheck`,
    method: 'post',
    data
  })
}

export function BindWallet(data) { // 绑定已开通钱包
  return axios({
    url: `/manager/account/BindWallet`,
    method: 'post',
		data
  })
}

export function OpenWallet(data) { // 开通钱包
  return axios({
    url: `/manager/account/OpenWallet`,
    method: 'post',
		data
  })
}


// 资金管理-账户资产-提现记录
export function getwithdrawlist(params) {
  return axios({
    url:`/manager/operating/thirds/getwithdrawlist`,
    method:'get',
    params
  })
}


// 资金管理-钱包入账记录
export function walletEntryList(params) {
  params.loading = true
  return axios({
    url:`/manager/trade/pay/incomeOrder`,
    method:'get',
    params
  })
}

// 资金管理-钱包入账记录-详情
export function walletEntryDetail(id) {
  return axios({
    url: `/manager/trade/pay/incomeOrder/detail/${id}`,
    method: 'get'
  })
}

// 资金管理-钱包提现记录
export function walletWithdrawalList(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/settleorders`,
    method: 'get',
    params
  })
}

// 资金管理-钱包提现记录-详情
export function walletWithdrawalDetail(settleOrderId) {
  return axios({
    url: `/manager/trade/allocfund/settleorders/${settleOrderId}`,
    method: 'get'
  })
}

// 资金管理-钱包提现记录-订单号搜索
export function walletWithdrawalSearch(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/settleorders/search`, 
    method: 'get',
    params
  })
}

// 资金管理-钱包分账记录
export function getTransferorder(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/transferorder`,
    method: 'get',
    params
  })
}
export function getTransferorderSearch(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/transferorder/search`,
    method: 'get',
    params
  })
}
// 资金管理-钱包分账记录-详情
export function getTransferordersDetail(orderId) {
  return axios({
    url: `/manager/trade/allocfund/transferorder/${orderId}`,
    method: 'get'
  })
}

// 营业执照识别
export function bizlicenseocr(params) {
  params.loading = true
  return axios({
    url: `/wxapi/thirds/bizlicenseocr`,
    method: 'get',
    params
  })
}

// 身份证识别
export function idcardocr(params) {
  params.loading = true
  return axios({
    url: `/wxapi/thirds/idcardocr`,
    method: 'get',
    params
  })
}

//上传生成的图片
export function uploadFile(data){
  return axios({
      url:'/manager/common/upload1',
      method:"POST",    
      data,
  })
}

// 页面截图-店铺货物截图-数据
export function downProductList(params) {
  return axios({
    url: `/manager/operating/listProduct`,
    method: 'get',
    params
  })
}
// 页面截图-店铺结算记录截图-数据
export function downWithdrawList(params) {
  return axios({
    url: `/wxapi/thirds/getwithdrawlist`,
    method: 'get',
    params
  })
}
// 页面截图-店铺认证截图-数据
export function downAppletList(params) {
  return axios({
    url: `/manager/account/small/apps`,
    method: 'get',
    params
  })
}



// ------------------------------------------------------------------------------

//商品佣金 - 同步  post
export function goodsSync(appId) {
  return axios({
    url:`/wxapi/product/${appId}/sync`,
    method:'post',
  })
}

export function orderSync(appId,data) {
  return axios({
    url:`/wxapi/orders/${appId}/sync`,
    method:'post',
    data
  })
}

// ------------------------------------------------------------------------------