import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import basic from "./basic/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home, 
    children:[
      ...basic,
    ]
  },
  {
    path:'/login',
    name: 'login',
    component:()=>import('@/views/login/login')
  }
]

const originalPush = VueRouter.prototype.push

// 修改原型对象中的push函数
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
