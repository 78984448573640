<template>
  <div id="mianTemp">
    <div class="mianHeader1 openSidebar">
      <div class="mianHeader">
        <div class="logo clear">
          <!-- <span v-if="!$store.state.isCollapse"> -->
          <span>
            <div class="logoImg"></div>
            <div class="logoText">服务商后台</div>
          </span>
          <!-- <div v-else class="logoImg">
            <img src="../assets/logo.png" alt="服务商后台">
          </div> -->
        </div>

        <div class="admin" style="margin-top: 0px">
          <div class="user-names">
            <el-popover placement="top-end" width="120" trigger="hover">
              <div class="userNameTogo">
                <div class="item" @click="setPassShow">
                  <img src="@/assets/suo2.png" alt="" class="iconImg" />
                  <span style="margin-left: 42px; color: #999"
                    >{{ userInfo.password ? "设置" : "修改" }}密码</span
                  >
                </div>
                <!-- <div style="text-align: left;margin-left: 42px;color:#999;line-height: 20px;font-size: 12px;" v-if="userInfo.reservedMsg != '' && userInfo.reservedMsg != null">
                  <span>预留信息：{{ userInfo.reservedMsg }}</span>
                </div> -->
                <div class="item" @click="layout">
                  <img src="@/assets/exit.png" alt="" class="iconImg" />
                  <span style="margin-left: 42px; color: #999">退出登录</span>
                </div>
              </div>
              <div slot="reference">
                <div class="userNameSet">
                  <div class="userNameSetTit">
                    <p style="line-height: 24px; width: 100%">
                      {{ userInfo.username }}
                    </p>
                    <p
                      style="line-height: 24px; width: 100%"
                      v-if="
                        userInfo.reservedMsg != '' &&
                        userInfo.reservedMsg != null
                      "
                    >
                      预留信息：{{ userInfo.reservedMsg }}
                    </p>
                  </div>
                  <i class="el-submenu__icon-arrow el-icon-arrow-down"></i>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="contentBigBox">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <Modal
      v-model="passShow"
      title="修改密码"
      closable
      width="490px"
      @on-cancel="passShow = false"
    >
      <ul class="overAccount_ul">
        <li class="ul_left" style="width: 320px">
          <div class="overAccount_ul_title"><i>*</i>旧密码</div>
          <Input
            v-model="formData.oldPassword"
            placeholder="输入旧密码"
            class="width100"
            type="password"
            password
          ></Input>
          <div class="overAccount_ul_title"><i>*</i>新密码</div>
          <Input
            v-model="formData.newPassword"
            placeholder="输入新密码"
            class="width100"
            type="password"
            password
          ></Input>
          <div class="overAccount_ul_title"><i>*</i>确认密码</div>
          <Input
            v-model="formData.reNewPassword"
            placeholder="再次输入新密码"
            class="width100"
            type="password"
            password
          ></Input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <Button class="exitBt" @click="passShow = false">取 消</Button>
        <Button class="addBt" @click="editPass">确 定</Button>
      </span>
    </Modal>
    <lissModel
      v-model="showTipsModal"
      :mode="mode"
      @close="showTipsModal = false"
    ></lissModel>
  </div>
</template>
<script>
import { setPass, layout } from "@/api/login.js";
import lissModel from "@/components/lissModel/index";
import md5 from "js-md5";
export default {
  components: { lissModel },
  data() {
    return {
      passShow: false,
      active_route: "basic",
      formData: {
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
      },
      timeOut: 0,
      codeShow: null,
      phone: "",
      userInfo: "",
      mode: "",
      showTipsModal: false,

      newHandle: null,
    };
  },
  created() {
    this.active_route = this.$route.path === "/" ? "basic" : "account";

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.phone =
      this.userInfo.phoneNumber &&
      this.userInfo.phoneNumber.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2");

    if (sessionStorage.getItem("reservedMsg") != undefined) {
      this.userInfo.reservedMsg = sessionStorage.getItem("reservedMsg");
    }

    if (this.userInfo.needChangePassword) {
      this.modal();
    }

    // if (this.userInfo.password) {
    //   this.modal('请尽快设置密码', '设置', () => {
    //     this.setPassShow();
    //   }, () => {
    //     // 跳转设置安全密码
    //     if (this.userInfo.tradePassword) {
    //       this.modal('请尽快前往设置页，设置安全密码', '去设置', () => {
    //         this.$router.push({
    //           name: "security_setting",
    //         });
    //       });
    //     }
    //   });
    // } else {
    //   if (this.userInfo.tradePassword) {
    //     this.modal('请尽快前往设置页，设置安全密码', '去设置', () => {
    //       this.$router.push({
    //           name: "security_setting",
    //       });
    //     });
    //   }
    // }

    let message = null;
    const handleAction = (e) => {
      if (e.target.dataset.value) {
        const { resources } = JSON.parse(sessionStorage.getItem("userInfo"));
        const divValue = e.target.dataset.value;
        if (
          resources.split(";").indexOf(divValue) == -1 &&
          resources != "ALL"
        ) {
          message = this.$Message.error("无操作权限，请联系超级管理员开通权限");
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };
    this.newHandle = function (event) {
      handleAction(event);
    };
    document.addEventListener("click", this.newHandle, true);
  },
  destroyed() {
    document.removeEventListener("click", this.newHandle, true);
  },
  watch: {
    $route: {
      handler: function (val) {
        this.active_route = val.matched[1].name;
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    // 30天重置密码
    modal(
      content = "密码已长时间没修改，请尽快更新",
      btnText = "修改",
      okCallback,
      cancelCallback
    ) {
      this.$Modal.confirm({
        title: "提示",
        content: content,
        okText: btnText,
        onOk: () => {
          // okCallback && okCallback()
          this.setPassShow();
        },
        onCancel: () => {
          cancelCallback && cancelCallback();
        },
      });
    },
    // 修改密码展示
    setPassShow() {
      this.formData = {
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
      };
      this.passShow = true;
    },
    // 修改密码
    editPass() {
      let data = JSON.parse(JSON.stringify(this.formData));
      let passwordTest =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
      if (!data.oldPassword) {
        this.$Message.error("请输入旧密码");
        return;
      }
      if (!data.newPassword) {
        this.$Message.error("请输入新密码");
        return;
      }
      // 正则验证密码复杂度

      if (!passwordTest.test(data.newPassword)) {
        return this.$Message.error(
          "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位及以上 "
        );
      }
      if (!data.reNewPassword) {
        this.$Message.error("请再次输入新密码");
        return;
      }
      if (data.newPassword != data.reNewPassword) {
        this.$Message.error("两次输入新密码不一致");
        return;
      }
      data.oldPassword = md5(data.oldPassword);
      data.newPassword = md5(data.newPassword);
      delete data.reNewPassword;
      setPass(data).then((res) => {
        if (res.resultStatus) {
          this.$Message.success("修改成功");
          sessionStorage.clear();
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
            this.passShow = false;
          }, 1000);
        }
      });
    },
    // 退出登陆
    layout() {
      layout().then((res) => {
        if (res.resultStatus) {
          sessionStorage.clear();
          localStorage.clear();
          this.$router.push({
            name: "login",
          });
        }
      });
    },
  },
};
</script>
<style lang="less">
.userNameSet {
  padding: 0px 30px;
  position: relative;
}
.userNameSet > i {
  position: absolute;
  right: 0px;
  color: #909399;
}
.userNameSet .userNameSetTit {
  text-align: right;
  color: #909399;
  font-size: 14px;
  height: 50px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  box-sizing: border-box;
  max-width: 120px;
}
.userNameSet .userNameSetTit p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.userNameSet:hover .userNameSetTit {
  color: var(--themeColor);
}
.userNameSet:hover > i {
  transform: rotate(180deg);
}
.userNameTogo {
  text-align: right;
  margin: 0;
}
.userNameTogo .item {
  position: relative;
  text-align: left;
  line-height: 36px;
  cursor: pointer;
  font-size: 12px;
}

#mianTemp {
  width: 100%;
  min-width: 1200px;
  min-height: 100%;
}

.content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.contentBigBox {
  width: 100%;
  background: var(--navBg);
}

.admin {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 56px;
  overflow-x: hidden;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.getCode {
  display: inline-block;
  width: 70px;
  margin-left: 15px;
  color: var(--themeColor);
  cursor: pointer;
}

.admin .el-menu--horizontal > .el-submenu .el-submenu__title:hover,
.admin .el-menu--horizontal > .el-submenu .el-submenu__title:focus {
  color: var(--themeColor) !important;
}
.admin .el-menu--horizontal > .el-submenu .el-submenu__title {
  display: flex;
  align-items: center;
}
.admin ul {
  border: 0 !important;
}

.headnavBox {
  float: left;
  margin-left: 60px;
}

.headnav li {
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 56px;
  margin-right: 40px;
}

.headnav li.active {
  border-bottom: 0;
}

.headnav li.active a {
  color: var(--themeColor);
}

.headnav li:hover a {
  color: var(--themeColor);
}
.headnav li:last-child {
  margin-right: 0;
}
.logo {
  float: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.logo i {
  display: inline-block;
  float: right;
  margin-left: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--label);
  line-height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logo i .openImg {
  width: 20px;
  height: 20px;
  background: url(../assets/open.png);
}

.logo i .is-active {
  transform: rotateY(180deg);
}

.logo span {
  height: 56px;
  display: flex;
  align-items: center;
}

.logo span .logoText {
  color: var(--label);
  font-size: 14px;
  font-weight: 400;
  margin-left: 22px;
}

.logo span .logoImg {
  width: 142px;
  height: 24px;
  background-image: url(../assets/logox-h.png);
  background-size: 100% 100%;
  // position: absolute;
  // left: 0;
  // top: 50%;
  // transform: translateY(-50%);
}

.logo i:hover {
  color: var(--themeColor);
}

.logoImg {
  width: 64px;
  height: 56px;
  display: flex;
  align-items: center;
}
.logoImg img {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
}

.mianHeader1 {
  width: 100%;
  height: 56px;
  background: rgba(255, 255, 255, 1);
  position: relative;
  top: 0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  height: 56px;
  padding: 0px 24px 0 0;
  box-sizing: border-box;
  z-index: 100;
}

.mianHeader1 .mianHeader {
  width: 1400px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.mianHeader1.hideSidebar .mianHeader {
  width: 1400px;
}
.mianHeader1.openSidebar .mianHeader {
  width: 100%;
}

#mian {
  width: 100%;
  min-height: 800px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}

#mian::after {
  clear: both;
}

.adminCH ul {
  border: 0 !important;
  height: 56px;
  margin-top: 0px;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0 !important;
  /* color: #636d7e !important; */
  color: var(--label) !important;
  font-weight: 548;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  color: #909399 !important;
  height: 56px !important;
  line-height: 56px !important;
  background-color: #fff !important ;
}

.adminCH .el-menu--horizontal > .el-submenu .el-submenu__title:hover,
.adminCH .el-menu--horizontal > .el-submenu .el-submenu__title:focus {
  color: var(--themeColor) !important;
}

.el-submenu.is-active .el-submenu__title {
  border-bottom-color: #fff !important;
}

.el-aside .el-submenu__title {
  box-sizing: border-box;
  height: 40px !important;
  line-height: 40px !important;
  display: inline-block;
  width: 177px;
  border-radius: 10px;
  padding-left: 24px;
  padding-right: 18px;
  margin-left: 12px;
  /* font-weight: 700; */
}

.el-submenu .el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  display: inline-block;
  margin-left: 12px;
  width: 177px;
  min-width: 177px !important;
  position: relative;
  /* color: #636d7e; */
  color: var(--label);
  border-radius: 0;
  box-sizing: border-box;
  padding: 0px 0px 0px 40px !important;
  border-radius: 10px;
}

#subme .el-menu-item {
  width: 100px !important;
}

.adminCH .el-menu .el-menu--popup .el-menu--popup-bottom-start {
  width: 100px !important;
  overflow-x: hidden;
}

.adminCH .el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  top: 3px !important;
  min-width: 140px !important;
  left: 13px;
}

.list .right_con h5 a,
.list .left_con h5 a {
  color: var(--togoBtn) !important;
  font-size: 14px;
  font-family: Microsoft YaHei;
}
</style>
<style scoped>
.tab-name {
  position: relative;
}

.tab-name span {
  display: block;
  text-align: center;
}

.iconImg {
  width: auto;
  height: 14px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
  text-align: left;
}

.overAccount_ul /deep/ .ivu-input,
.overAccount_ul /deep/ .ivu-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  margin: 0 auto;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
  color: #ffffff !important;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff !important;
  color: var(--title) !important;
  border-color: #d7dae2 !important;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}
.no_change.el-menu-item.is-active::before,
.no_change.el-menu-item.is-active {
  background-color: #fff !important;
}
</style>
<style scoped>
.tab-name {
  position: relative;
}

.tab-name span {
  display: block;
  text-align: center;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
  text-align: left;
}

.overAccount_ul /deep/ .ivu-input,
.overAccount_ul /deep/ .ivu-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  width: 200px;
  margin: 0 auto;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor) !important;
  color: #ffffff !important;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff !important;
  color: var(--title) !important;
  border-color: #d7dae2 !important;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}
.no_change.el-menu-item.is-active::before,
.no_change.el-menu-item.is-active {
  background-color: #fff !important;
}

@media screen and (max-width: 1399px) {
  .mianHeader1 .mianHeader {
    width: 1320px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .mianHeader1.hideSidebar .mianHeader {
    width: 1320px;
  }
}
@media screen and (max-width: 1200px) {
  .mianHeader1 {
    padding: 0px;
  }
  .mianHeader1 .mianHeader {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .mianHeader1.hideSidebar .mianHeader {
    width: 100%;
  }
}
</style>
