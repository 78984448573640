export const URL_API = {

  // baseURL:'http://106.53.231.120:9311', //测试api
  // upload: 'http://106.53.231.120:9311/manager/common/upload' ,//测试上传文件接口
  // codeUrl:'http://106.53.231.120:9311/manager/common/imagecode',//测试二维码
  // outputUrl: 'http://106.53.231.120:9311',//测试导出地址

  baseURL:'https://isv.pay.api.youthsrun.com', //正式api
  upload: 'https://isv.pay.api.youthsrun.com/manager/common/upload' ,//正式上传文件接口
  codeUrl:'https://isv.pay.api.youthsrun.com/manager/common/imagecode',//正式二维码
  outputUrl:'https://isv.pay.api.youthsrun.com',//正式导出地址

  // baseURL: "http://192.168.0.33:9311",
  // upload: 'http://192.168.0.33:9311/manager/common/upload' ,//测试上传文件接口
  // codeUrl: 'http://192.168.0.33:9311/manager/common/imagecode',//本地二维码
  
}