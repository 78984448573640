import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入eleui核心库
import { Popover, Tabs, TabPane, Tree , Select , Option, Message, Upload } from 'element-ui'

// 引入样式
import 'element-ui/lib/theme-chalk/index.css'
import "element-ui/packages/theme-chalk/lib/select.css"
import "element-ui/packages/theme-chalk/lib/option.css"
Vue.use(Popover).use(Tabs).use(TabPane).use(Tree).use(Select).use(Option).use(Upload)

Message.install = function (Vue, options) {
  Vue.prototype.$message = Message
}
Vue.use(Message)

import ViewUI from 'view-design'
import './theme/index.less'
Vue.use(ViewUI, {
  size: 'large',
  modal: {
    maskClosable: false
  }
})

import plugins from './plugins/index'
Vue.use(plugins)

//bus 总线传值
Vue.prototype.bus = new Vue();

function findValues(obj, targetValue, results = []) {
  let currentObj = null; // 追踪当前对象的引用

  function search(obj) {
    for (let key in obj) {
      if (obj[key] === targetValue) {
        results.push(currentObj || obj);
      } else if (typeof obj[key] === 'object') {
        currentObj = obj[key];
        search(obj[key]);
      }
    }
  }

  search(obj);
  return results;
}


Vue.directive('auth', {
  // 当被绑定的元素插入到 DOM 中时……
  bind: function (el, binding) {
    // 聚焦元素
    const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
    const results = findValues(store.state.rolesData, binding.value);
		if(_resources=='ALL'){
      if (results && results.length > 0 && results[0] != null) {
        el.setAttribute('data-level', results[0].lv);
        el.setAttribute('data-value', results[0].value);
      } else {
        el.setAttribute('data-level', 1);
        if(binding.value.indexOf('/') !== -1){
          el.setAttribute('data-value', binding.value);
        }
       
      }
      el.disabled = false;
			// el.style.visible = true;
		}else {
			if (_resources && _resources.split(';').indexOf(binding.value) !== -1 || binding.value=='ALL') {
        if (results && results.length > 0 && results[0] != null) {
          el.setAttribute('data-level', results[0].lv);
          el.setAttribute('data-value', results[0].value);
        } else {
          el.setAttribute('data-level', 1);
          if(binding.value.indexOf('/') !== -1){
            el.setAttribute('data-value', binding.value);
          }
        }
        el.disabled = false;
			  // el.style.visible = true;
			} else {
        if(binding.value.indexOf('/') !== -1){
          el.setAttribute('data-value', binding.value);
        }
        // el.disabled = true;
			  // el.style.display = 'none';
			}
		}
    el.focus()
  }
})


Vue.directive('alterELDialogMarginTop'/*修改elementUI中el-dialog顶部的距离,传入值eg:{marginTop:'5vh'} */, {
  inserted(el, binding, vnode) {
    el.firstElementChild.style.marginTop = binding.value.marginTop
  }
})


router.beforeEach((to, from, next) => {
  // 判断用户是否已经登录 0503
  // 0530 设置登录过期定时器
  if (to.path !== '/login' && to.path !== '/noAccess') {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    if (!userInfo) {
      next({ path: '/login' });
    } else {
      const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
      const value = to.meta.value;
      if(value != undefined){
        if (_resources && _resources.split(';').indexOf(value) !== -1 || _resources=='ALL') {
          next();
        }else{
          router.push('/noAccess')
        }  
      }
    }
  } else {
    next();
  }
})



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
