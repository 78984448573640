<template>
  <Modal width="450px" :title="content.title || '提示'" v-model="content.isShow" :mask-closable="true">
    <div class="container flex-center">
      <div class="message-type" :class="content.iconClass ? content.iconClass : 'el-icon-warning'" :style="{'color': content.iconColor ? content.iconColor : '#FE8A23'}"></div>
      <div class="message-box">
        <p v-if="content.isHtmlText" v-html="content.message"></p>
        <p v-else v-text="content.message"></p>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <Button plain class="plain-btn" size="small" @click="cancel">{{ content.cancelText || '取 消' }}</Button>
      <Button type="primary" size="small" @click="confirm">{{ content.confirmText || '确 定' }}</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      content: {
        title: "",
        message: "",
        isShow: false,
        isHtmlText: false, // 是否使用html
        iconClass: '', // 图标类名
        iconColor: '', // 图标颜色
        confirmText: '', // 确认按钮文案
        cancelText: '', // 取消按钮文案
      }
    };
  },
  methods: {
    cancel () {},
    confirm () {},
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 90%;
  display: flex;
  margin: 0 auto;
  align-content: center;
  .message-type {
    font-size: 24px!important;
  }
  .message-box {
    font-size: 14px;
    color: #333;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 24px;
  }
}
</style>

