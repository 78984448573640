import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '@/router'
import { URL_API } from '@/utils/apiUrl.js'

import { Loading } from 'element-ui';
import { Message } from 'view-design';
var show = true
const service = axios.create({
  headers: { 
    'Content-Type': 'application/json;chartset=utf-8',
    'token':store.getters.getToken,
   },
  baseURL: URL_API.baseURL,
  timeout: 600000,
  transformRequest: [(data) => {
    return JSON.stringify(data);
  }],
  withCredentials: true,
});
let loadingInstance = null
const err = (error) => {
  if (loadingInstance && loadingInstance.close) {
    loadingInstance.close()
  }
  if (JSON.stringify(error).indexOf('600000ms') !== -1) {
    Message.error('请求超时！！');
    return Promise.reject(error)
  }
  if (error.response) {
    const data = error.response.data
    if (data.errorCode == "020030") {
      if (show) {
        Message.error('会话已超时，请重新登录');
        show = false
        setTimeout(() => {
          show = true
        }, 1000)
      }
      router.push({ name: 'login' })
      localStorage.clear()
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  config.headers.token = store.getters.getToken;
  if (config.params && config.params.loading) {
    delete config.params.loading
    loadingInstance = Loading.service({
      lock: true,
      background: 'rgba(255, 255, 255, 0.5)'
    });
  }
  return config
}, err)

// 响应拦截
service.interceptors.response.use((response) => {
  if (loadingInstance && loadingInstance.close) {
    loadingInstance.close()
  }
  if (response.data && !response.data.hasOwnProperty("resultStatus")) {
    return response.data
  }
  if (response.data.resultStatus) {
    return response.data
  } else {
    if (response.data.errorCode == '020030') {
      router.push({ name: 'login' })
      localStorage.clear()
    } else {
      if(response.config.url == "/manager/operating/putWxInvitation/0" || response.config.noToast === true){
        return response.data
      }else {
        Message.destroy()
        Message.error(response.data ? response.data.errorMessage : '请求错误!!');
        // return response.data
      }
    }
  }
}, err)
export default service
